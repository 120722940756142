import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { Breakpoints, CoinIconSize } from "src/enums";
import { ComponentWithClassName, GiftType } from "src/types/common";
import { RootState } from "state/delegate";
import { giftsCacheSelectors, userSelectors } from "state/selectors";
import { FormattedNumber } from "ui/common/Formatted";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { AllTypographyTypes } from "ui/common/typography/types";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";

import { ReactComponent as CoinIcon } from "img/ic_coin.svg";
import { ReactComponent as DiamondIcon12 } from "img/ic_diamond_12.svg";
import { ReactComponent as DiamondIcon16 } from "img/ic_diamond_16.svg";
import styles from "./GiftCoinsView.scss";

interface GiftCoinsViewProps {
  boughtGiftSetting?: {
    accountId: string;
    withPoint: boolean;
  };
  coinSize?: CoinIconSize;
  gift: Omit<GiftType, "id">;
  giftBalance?: number;
  isFreeFollowGift?: boolean;
  isLarge?: boolean;
  otherAmount?: number;
  settingsPrice: {
    onlyCoins?: boolean;
  };
  showBalance?: boolean;
  typography?: AllTypographyTypes;
}

const selector = (state: RootState) => ({
  pointsBalance: userSelectors.getPointsBalance(state),
  isBuyWithCoins: giftsCacheSelectors.isBuyGiftWithCoins(state),
  myAccountId: userSelectors.getMyAccountId(state),
});

const GiftCoinsView: ComponentWithClassName<GiftCoinsViewProps> = ({
  className,
  settingsPrice: { onlyCoins = true },
  gift,
  giftBalance,
  showBalance = false,
  otherAmount,
  boughtGiftSetting,
  isLarge = false,
  coinSize = CoinIconSize.SMALL,
  typography,
  isFreeFollowGift,
}) => {
  const breakpoint = useBreakpointPrecise();
  const { isBuyWithCoins, pointsBalance, myAccountId } = useSelector(
    selector,
    shallowEqual
  );

  let isCoins = onlyCoins || isBuyWithCoins;

  if (boughtGiftSetting !== undefined) {
    const meBoughtGift = myAccountId === boughtGiftSetting.accountId;
    isCoins = meBoughtGift ? !boughtGiftSetting.withPoint : true;
  }

  const conditionOnlyCoins =
    isCoins || !gift.withdrawInPoint || pointsBalance < gift.withdrawInPoint;

  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  const typographyType = useMemo(() => {
    if (typography) {
      return typography;
    }

    if (!isLarge) {
      return TYPOGRAPHY_TYPE.MINI;
    }

    return isDesktop ? TYPOGRAPHY_TYPE.HEADLINE4 : TYPOGRAPHY_TYPE.HEADLINE6;
  }, [isDesktop, isLarge, typography]);

  return (
    <Typography
      type={typographyType}
      className={classnames(
        styles.root,
        isLarge && styles.large,
        isDesktop && styles.desktop,
        className
      )}
      as="div"
    >
      {showBalance && giftBalance ? (
        <span>{`Free x${giftBalance}`}</span>
      ) : (
        <div
          className={styles.priceValue}
          data-testid="gift-price-coins-number"
        >
          {isFreeFollowGift ? (
            <FormattedMessage id="gifts.following-gift" defaultMessage="Free" />
          ) : (
            <>
              {conditionOnlyCoins ? (
                <CoinIcon
                  className={classnames(styles.coin, styles[coinSize])}
                />
              ) : (
                <>
                  {isLarge && breakpoint === Breakpoints.DESKTOP ? (
                    <DiamondIcon16 className={styles.diamond} />
                  ) : (
                    <DiamondIcon12 className={styles.diamond} />
                  )}
                </>
              )}
              <FormattedNumber
                value={
                  otherAmount ||
                  (conditionOnlyCoins
                    ? gift.priceInCredit
                    : gift.withdrawInPoint)
                }
              />
            </>
          )}
        </div>
      )}
    </Typography>
  );
};

export default GiftCoinsView;
